#App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  color: #ffff;
}

.App-breve-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 1%;
}

.breve-logo {
  width: 30%;
  padding: 15px;
  height: auto;
}

.soon {
  text-align: center;
  text-decoration-color: #ffff;
  font-family: 'Sora', sans-serif;
  letter-spacing: 3px;
  font-size: 20px;
  font-weight: 400;
  padding: 5px;
}

.mad-spain {
  text-align: center;
  font-family: 'Sora', sans-serif;
  text-decoration-color: #ffff;
  letter-spacing: 1.5px;
  font-size: 20px;
  font-weight: 400;
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
} */
/* @media (prefers-reduced-motion: no-preference) {
  .App {
    animation: App-logo-spin infinite 5s linear;
  }
} */

/* @media (max-width: 768px) {
  .breve-logo {
    width: 100%;
  }
} */

@media (max-width: 768px) {
  .App-breve-logo {
    padding: 22px;
  }

  .breve-logo {
    width: 80%;
  }
  
  .soon, .mad-spain {
    font-size: 13px;
  }
}